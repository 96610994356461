import * as React from 'react';
import Advantages from './Advantages/Advantages';
import Main, { MAIN_BACKGROUND_STYLES } from '../_Shared/Main/Main';
import { BTN_STYLES } from '../_Shared/Button/Button';
import OurProcess from '../_Shared/OurProcess/OurProcess';
import Cooperation from '../Home/Cooperation/Cooperation';
import YourIdea from '../_Shared/YourIdea/YourIdea';
import AccordionWrapper from '../_Shared/AccordionWrapper/AccordionWrapper';
import Testimonials from '../_Shared/Testimonials/Testimonials';
import { NewIndustriesPageData } from '../ServicePage/types';
import ServicesList from '../_Shared/ServicesList/ServicesList';
import FeaturesToBuildSolutions from './FeaturesToBuildSolutions/FeaturesToBuildSolutions';
import Idea from '../Home/Idea/Idea';
import TechnologiesStack from '../_Shared/TechnologiesStack/TechnologiesStack';
import Slider from '../_Shared/Slider/Slider';
import RelatedSteps from '../_Shared/RelatedSteps/RelatedSteps';

const NewIndustriesPage = ({ data }: NewIndustriesPageData) => {
  const {
    title,
    description,
    image,
    advantages,
    faqMarkup,
    userForm,
    solutionListContent,
    industryListContent,
    ourProcess,
    caseStudySlider,
  } = data.sanityNewIndustries;
  const mainImage = image.asset.url;
  const technologiesContent = data.sanityNewIndustries._rawTechnologiesContent;
  const processSteps = industryListContent.processSteps;
  const processStepsTitle = industryListContent.processStepsTitle;

  return (
    <>
      <Main
        className={MAIN_BACKGROUND_STYLES.NEW_INDUSTRIES}
        title={title}
        description={description}
        classNameButton={BTN_STYLES.BTN_PRIMARY_LARGE}
        btnTitle="Contact us"
        image={mainImage}
      />
      <ServicesList data={industryListContent} />
      <Advantages data={advantages} />
      <Idea className="ideaCustom" />
      <FeaturesToBuildSolutions data={solutionListContent} />
      {technologiesContent && <TechnologiesStack data={technologiesContent} />}
      <Slider data={caseStudySlider} />
      <OurProcess data={ourProcess} />
      <Cooperation />
      <Testimonials />
      <YourIdea data={userForm} />
      {processSteps && <RelatedSteps steps={processSteps} title={processStepsTitle} />}
      <AccordionWrapper data={faqMarkup} />
    </>
  );
};

export default NewIndustriesPage;

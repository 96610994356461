import * as React from 'react';
import PageLayout from '../components/_Shared/PageLayout/PageLayout';
import 'normalize.css';
import '../globalStyles/_fonts.scss';
import '../globalStyles/_styles.scss';
import NewIndustriesPage from '../components/NewIndustriesPage/NewIndustriesPage';
import { graphql } from 'gatsby';
import { NewIndustriesPageData } from '../components/ServicePage/types';

export const query = graphql`
  query MyNewIndustries($id: String) {
    sanityNewIndustries(id: { eq: $id }) {
      _rawTechnologiesContent
      title
      description
      slug {
        current
      }
      image {
        asset {
          url
        }
      }
      seo {
        description
        title
      }
      advantages {
        number
        step
      }
      industryListContent {
        title
        description
        icon {
          alt
          image {
            asset {
              url
            }
          }
        }
        image {
          alt
          image {
            asset {
              url
            }
          }
        }
        serviceList {
          answer
          title
        }
        processStepsTitle
        processSteps {
          title
          link
          icons {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
      }
      solutionListContent {
        title
        listDescription
        secondaryListDescription
        listContent {
          features {
            title
            description
            icon {
              image {
                asset {
                  url
                }
              }
            }
          }
        }
        secondaryListContent {
          features {
            title
            description
            icon {
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      ourProcess {
        _rawContent
        image {
          asset {
            url
          }
        }
      }
      faqMarkup {
        question
        _rawAnswers
      }
      userForm {
        managerName
        managerPosition
        description
        image {
          asset {
            url
          }
        }
        openFormLinks {
          title
          image {
            caption
            alt
            image {
              asset {
                url
              }
            }
          }
        }
      }
      caseStudySlider {
        title
        description
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
        technologiesImage {
          asset {
            url
          }
        }
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const NewIndustries = ({ data }: NewIndustriesPageData) => {
  const { allSanityFooter } = data;
  const { title, description } = data.sanityNewIndustries.seo;
  const microMarkup = data.sanityNewIndustries.faqMarkup;

  return (
    <PageLayout title={title} description={description} footerData={allSanityFooter} markData={microMarkup}>
      <NewIndustriesPage data={data} />
    </PageLayout>
  );
};

export default NewIndustries;
